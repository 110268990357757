import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/MainPages/LoginPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/LandingPage",
    name: "LandingPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/MainPages/LandingPage.vue"
      ),
    children: [
      
      {
        path: "/Customers",
        name: "Customers",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Customers.vue"
          ),
      },
      {
        path: "/Users",
        name: "Users",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Users.vue"
          ),
      },
      {
        path: "/Countries",
        name: "Countries",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Countries.vue"
          ),
      },
      {
        path: "/CountryRegion",
        name: "CountryRegion",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/CountryRegion.vue"
          ),
      },
      {
        path: "/States",
        name: "States",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/States.vue"
          ),
      },
      {
        path: "/StateRegion",
        name: "StateRegion",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/StateRegion.vue"
          ),
      },
      {
        path: "/PinCode",
        name: "PinCode",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/PinCode.vue"
          ),
      },
      {
        path: "/Territories",
        name: "Territories",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ChildPages/Territories.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
