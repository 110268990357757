<template>
  <v-app>
    <Snackbar :SnackbarComponent="SnackbarComponent" />
    <v-card tile :height="$vuetify.breakpoint.height" flat class="CardDesign">
      <v-container fluid fill-height class="cardContent">
        <v-layout align-center justify-center>
          <div class="wrapper">
            <v-card color="transparent" flat class="text-center">
              <v-row>
                <v-col cols="12" align="center">
                  <v-img max-width="180px" src="@/assets/logo4.png" />
                </v-col>
              </v-row>
              <v-form ref="LoginForm" class="pt-6">
                <v-text-field
                  flat
                  dense
                  outlined
                  dark
                  background-color="#ffffff1d"
                  class="white--text FontSize12px"
                  :readonly="IsOTPFieldEnabled"
                  :filled="IsOTPFieldEnabled"
                  v-model="Login.email_id"
                  :rules="[(v) => !!v || 'required']"
                  @keypress.enter.prevent="
                    ValidateMethod(
                      IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP'
                    )
                  "
                >
                  <template v-slot:label>
                    <div class="FontSize12px textFieldLabelColor">
                      Email Address
                    </div>
                  </template>
                </v-text-field>
              </v-form>
              <!-- <div class="text-left FontSize12px white--text font-weight-bold" v-if="IsOTPFieldEnabled">
                  Enter OTP which we sent to your registered above Email ID
                </div> -->
              <v-otp-input
                v-show="IsOTPFieldEnabled"
                ref="autoFocusOtpField"
                :length="6"
                v-model="Login.otp"
                type="number"
                :rules="[(v) => !!v || 'required']"
                @keypress.enter.prevent="
                  ValidateMethod(IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP')
                "
              ></v-otp-input>
              <v-card-actions class="pa-0 ma-0">
                <v-btn
                  tile
                  depressed
                  block
                  :loading="LoginLoader"
                  color="primary"
                  class="rounded-lg"
                  @click="
                    ValidateMethod(
                      IsOTPFieldEnabled ? 'VERIFY_OTP' : 'SEND_OTP'
                    )
                  "
                  >{{ IsOTPFieldEnabled ? "Verify OTP" : "Login" }}</v-btn
                >
              </v-card-actions>
              <!-- <div class="pt-4">
                  <v-btn
                    v-if="IsOTPFieldEnabled && counter === 0"
                    small
                    text
                    :disabled="counter !== 0"
                    class="text-capitalize"
                    color="white"
                    @click="SignInMethod('SEND_OTP')"
                    >Resend OTP</v-btn
                  >
                  <span v-if="IsOTPFieldEnabled == true && counter !== 0" class="ml-2 white--text FontSize12px"
                    >Resend OTP available in {{ counter }}</span
                  >
                </div> -->
            </v-card>
          </div>
        </v-layout>
      </v-container>
    </v-card>
  </v-app>
</template>

<script>
import { Auth } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    IsOTPFieldEnabled: false,
    LoginLoader: false,

    counter: 0,
    interval: null,

    SnackbarComponent: {},
    Login: {
      email_id: "",
      otp: "",
    },
  }),
  mounted() {
    this.disableBrowserRoutingChanges();
  },
  methods: {
    disableBrowserRoutingChanges() {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    },
    ValidateMethod(action) {
      if (this.$refs.LoginForm.validate()) {
        this.SignInMethod(action);
      } else {
        this.SnackbarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          SnackbarText: `Please enter Email to Login`,
        };
      }
    },
    async SignInMethod(action) {
      switch (action) {
        case "SEND_OTP":
          try {
            this.LoginLoader = true;
            this.user = await Auth.signIn(this.Login.email_id);
            if (this.user.challengeName === "CUSTOM_CHALLENGE") {
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "#0e407b",
                SnackbarText: `OTP sent successfully to ${this.Login.email_id}`,
              };
              this.counter = 120;
              this.LoginLoader = false;
              this.$refs.autoFocusOtpField.reset();
              setTimeout(() => {
                this.IsOTPFieldEnabled = true;
                setTimeout(() => {
                  this.$refs.autoFocusOtpField.focus();
                }, 100);
              }, 1000);
              this.$forceUpdate();
              this.startCounter();
            }
          } catch (error) {
            this.SnackbarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              SnackbarText: error.message,
            };
            this.EmailErrorMessage = error.message;
            this.LoginLoader = false;
            console.log("Err", error);
          }
          break;
        case "VERIFY_OTP":
          try {
            this.LoginLoader = true;
            await Auth.sendCustomChallengeAnswer(this.user, this.Login.otp)
              .then(() => {
                this.SnackbarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "#0e407b",
                  SnackbarText: "Login Successfull",
                };
                this.LoginLoader = false;
                this.ActivateMethod();
              })
              .catch((err) => {
                this.SnackbarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "red",
                  SnackbarText: err.message,
                };
                this.OTPErrorMessage = err.message;
                this.LoginLoader = false;
              });
          } catch (error) {
            console.log("err", error);
          }
          break;
      }
    },
    startCounter() {
      this.interval = setInterval(() => {
        if (this.counter > 0) {
          this.counter--;
        } else {
          clearInterval(this.interval);
        }
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
    ActivateMethod() {
      sessionStorage.setItem("CURRENT_USER_LOGIN_MAILID", this.Login.email_id);
      setTimeout(() => {
        this.$router.push("LandingPage");
      }, 1000);
    },
  },
};
</script>

<style>
.CardDesign {
  background: url("~@/assets/bg2.jpg") !important;
  background-size: cover !important;
  height: 100vh !important;
}
.wrapper {
  width: 350px;
  max-height: 450px;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  position: absolute !important;
  -webkit-backdrop-filter: blur(10px);
}

.textFieldLabelColor {
  color: rgb(255, 255, 255) !important;
  font-weight: 600 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  /* background-color: #ffffff4d; */
}
</style>
